header {
  height: 105vh;
  padding-top: 1rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ============ CTA STYLE ============ */
.cta {
  height: 5rem;
  width: 30rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

/* ============ HEADER SOCIALS STYLE ============ */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

/* .header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
} */

/* ============ HEADSHOT STYLE ============ */
.headshot {
  /* background: linear-gradient(var(--color-primary), transparent); */
  height: 30rem;
  width: 30rem;
  position: absolute;
  left: calc(50% - 15rem);
  margin-top: 1rem;
  margin-bottom: 5px;
  border-radius: 2rem;
  overflow: hidden;
  /*padding: 5rem 1.5rem 1.5rem 1.5rem;*/
}

/* ============ SCROLL DOWN ============ */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  header {
    height: 82vh;
  }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .cta {
    width: 99%;
  }
}
