.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.portfolio__item-content {
  flex-grow: 1;
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  margin-top: 0.5rem;
}

.filter-icons {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  align-items: center;
  width: 60%;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 2rem;
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.filter-icons-title {
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}

.filter-icons-title > h5 {
  color: var(--color-primary);
}

.filter-icons-icons {
  color: var(--color-primary);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  width: 100%;
  font-size: 1.5rem;
}

.language__details-icon {
  color: var(--color-primary);
  transform: scale(2);
  margin: 2rem;
}

.language__details-icon:hover {
  color: white;
}

.language__details-icon:active {
  color: var(--color-primary-variant);
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .filter-icons {
    width: 85%;
  }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ========== */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .filter-icons {
    width: 90%;
  }
}
