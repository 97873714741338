.skills__container {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  align-items: center;
  width: 60%;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 2rem;
  background: var(--color-bg-variant);
  border-radius: 2rem;
  transition: var(--transition);
}

.skills__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.skills__container > div:hover {
  background: transparent;
  cursor: default;
}

.skills__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.skills__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem;
}

.skills__details {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
}

.skills__details-icon {
  color: var(--color-primary);
  transform: scale(2);
}

/*Tablet*/
@media screen and (max-width: 1024px) {
  .skills__container {
    grid-template-columns: 1fr;
    width: 85%;
  }

  .skills__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .skills__content {
    padding: 1rem;
  }
}

/*Phone*/
@media screen and (max-width: 600px) {
  .skills__container {
    gap: 1rem;
    width: 90%;
  }

  .skills__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
