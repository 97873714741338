footer {
    background: var(--color-primary-variant);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

.footer_copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

.footer_text {
    font-weight: bold;
    color: white;
}